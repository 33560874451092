import { Injectable } from '@angular/core';
import { Plugin, Cordova, AwesomeCordovaNativePlugin } from '@awesome-cordova-plugins/core';
import { Observable } from 'rxjs';

/* Call status */
export enum CallState {
  /* Call is being initialized */
  OutgoingInit = 'OutgoingInit',
  /* Call is in progress (comes in between init and ringing) */
  OutgoingProgress = 'OutgoingProgress',
  /* Call is ringing */
  OutgoingRinging = 'OutgoingRinging',
  /* Call active (connected) */
  Connected = 'Connected',
  Resuming = 'Resuming',
  /* Call has ended (connected) */
  Ended = 'End',
  Error = 'Error'
}

/* Registration status */
export enum RegistrationState {
  /* Initial state for registrations */
  None = 'None',
  /* Unregistration succeeded */
  Cleared = 'Cleared',
  /* Registration failed */
  Failed = 'Failed',
  /* Registration succeeded */
  Ok = 'Ok',
  /* Registration in progress */
  Progress = 'Progress',
}

/**
 * @name SipCall
 * @description
 * This lets you make calls using SIP  
 *
 * @usage
 * ```typescript
 * import { SipCall } from '@awesome-cordova-plugins/sip-call';
 *
 *
 * constructor(private sipCall: SipCall) { }
 *
 * ...
 *
 *
 * this.sipCall.login('username', 'P@sswo0rd', 'test-realm.com');
 *
 * ```
 */
@Plugin({
  pluginName: 'SipCall',
  plugin: 'cordova-plugin-sip-call', // npm package name, example: cordova-plugin-camera
  pluginRef: 'plugins.SipCall', // the variable reference to call the plugin, example: navigator.geolocation
  repo: '', // the github repository URL for the plugin
  install: '', // OPTIONAL install command, in case the plugin requires variables
  installVariables: [], // OPTIONAL the plugin requires variables
  platforms: ['Android'] // Array of platforms supported, example: ['Android', 'iOS']
})
@Injectable()
export class SipCall extends AwesomeCordovaNativePlugin {

  /**
   * Login with your VoIP provider
   * @param username {string} Username
   * @param password {string} Password
   * @param realm {string} Realm to connect to
   * @return {Promise<any>} Returns a promise that resolves when logged in
   */
  @Cordova()
  login(username: string, password: string, realm: string): Promise<any> {
    return; // We add return; here to avoid any IDE / Compiler errors
  }

  /**
   * Logout from your VoIP provider
   * @return {Promise<any>} Returns a promise that resolves when logged out
   */
  @Cordova()
  logout(): Promise<any> {
    return; // We add return; here to avoid any IDE / Compiler errors
  }

  /**
 * Call with your VoIP provider
 * @param address {string} Address
 * @param displayName {string} DisplayName
 * @return {Promise<any>} Returns a promise that resolves when logged in
 */
  @Cordova()
  call(address: string, displayName: string): Promise<any> {
    return; // We add return; here to avoid any IDE / Compiler errors
  }

  /**
   * Hangup the call
   * @return {Promise<any>} Returns a promise that resolves when call is terminated
   */
  @Cordova()
  hangup(): Promise<any> {
    return; // We add return; here to avoid any IDE / Compiler errors
  }
  
  /**
   * Mute the microphone
   * @param muted {boolean} Muted 
   * @return {Promise<any>} Returns a promise that resolves when mic is muted/enabled
   */
   @Cordova()
   setMicrophoneMuted(muted: boolean): Promise<any> {
     return; // We add return; here to avoid any IDE / Compiler errors
   }

  /**
   * Enables the speaker
   * @param enabled {boolean} Speaker state 
   * @return {Promise<any>} Returns a promise that resolves when the speaker is enabled/disabled
   */
   @Cordova()
   setSpeakerEnabled(enabled: boolean): Promise<any> {
     return; // We add return; here to avoid any IDE / Compiler errors
   }

  /**
   * RequestPermissions for the plugin (RECORD_AUDIO)
   * @return {Promise<any>} Returns a promise that resolves Permissions have been requested
   */
  @Cordova()
  requestPermissions(): Promise<any> {
    return; // We add return; here to avoid any IDE / Compiler errors
  }

  /**
   * OnCallStateChanged: is called when the call state has changed
   * @return {Observable<CallState>} Returns an Observable that emits when the call state has changed
   */
  @Cordova({
    observable: true
  })
  onCallStateChanged(): Observable<CallState> {
    return; // We add return; here to avoid any IDE / Compiler errors
  }

  /**
   * OnRegistrationStateChanged: is called when the registration state has changed
   * @return {Observable<RegistrationState>} Returns an Observable that emits when a the registration state has changed
   */
  @Cordova({
    observable: true
  })
  onRegistrationStateChanged(): Observable<RegistrationState> {
    return; // We add return; here to avoid any IDE / Compiler errors
  }
}
